import '../stylesheets/frontend/top.css.scss'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import ReactOnRails from 'react-on-rails'

import MiniRateChart from '../bundles/components/chart/MiniRateChart'
import MiniRateChartTable from '../bundles/components/MiniRateChartTable'
import WholeRateChartTable from '../bundles/components/WholeRateChartTable'
import CoinIcon from '../bundles/components/shared/CoinIcon'

const application = Application.start()
const context = require.context(
  'controllers',
  true,
  /^(?!.*\/admin\/).*_controller\.(js|ts)$/
)
application.load(definitionsFromContext(context))

ReactOnRails.register({
  MiniRateChart,
  MiniRateChartTable,
  WholeRateChartTable,
  CoinIcon,
})
